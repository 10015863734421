import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { aboutUs as data } from '../../data';
import styles from './styles.module.less';

const AboutUs = () => {
  const [newsIndex, setNewsIndex] = useState(0);
  const [newsIndexStep, setNewsIndexStep] = useState(() => {
    const width = document.documentElement.clientWidth;
    return width < 1440 ? 1 : 2;
  });
  const currentNewsItems = useMemo(
    () => data.news.slice(newsIndex, newsIndex + newsIndexStep),
    [newsIndex, newsIndexStep]
  );

  const prev = () =>
    setNewsIndex(
      (newsIndex + data.news.length - newsIndexStep) % data.news.length
    );
  const next = () =>
    setNewsIndex((newsIndex + newsIndexStep) % data.news.length);

  useEffect(() => {
    const handler = (evt: Event) => {
      const width = ((evt.currentTarget as Window).document ?? document)
        .documentElement.clientWidth;
      if (width < 1440 && newsIndexStep !== 1) {
        setNewsIndexStep(1);
      } else if (width >= 1440 && newsIndexStep !== 2) {
        setNewsIndexStep(2);
        if (newsIndex % 2 === 1) {
          setNewsIndex(newsIndex - 1);
        }
      }
    };
    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [newsIndexStep, newsIndex]);

  return (
    <div className={classnames(styles["container"])}>
      <div className={classnames(styles["background"])}>
        <div className={classnames(styles["background-bg"])}>
          <img
            className={classnames(styles["background-bg-img"])}
            src={data.backgroundImg}
            alt="红布林背景"
          />
        </div>
      </div>
      <div className={classnames(styles["foreground"])}>
        <div className={classnames(styles["left"])}>
          <div className={classnames(styles["english-title"])}>
            {data.englishTitle}
          </div>
          <div className={classnames(styles["title"])}>{data.title}</div>
          <div className={classnames(styles["content"])}>
            {data.content.split("\n").map((ctx, index) => (
              <div key={index}>
                <span>{ctx}</span>
                <br />
              </div>
            ))}
          </div>
        </div>
        <div className={classnames(styles["right"])}>
          <div className={classnames(styles["brand-news"])}>
            <div className={classnames(styles["title-container"])}>
              <div className={classnames(styles["column-title"])}>
                {data.brandNews}
              </div>
              <div className={classnames(styles["left-right-buttons"])}>
                <button
                  className={classnames(
                    styles["left-right-button"],
                    styles["left-button"]
                  )}
                  onClick={prev}
                ></button>
                <button
                  className={classnames(
                    styles["left-right-button"],
                    styles["right-button"]
                  )}
                  onClick={next}
                ></button>
              </div>
            </div>
            <div className={classnames(styles["news-container"])}>
              {currentNewsItems.map((item) => {
                return (
                  <div
                    className={classnames(styles["card-container"])}
                    key={item.title}
                  >
                    <div className={classnames(styles["news-image-container"])}>
                      <img
                        className={classnames(styles["news-image"])}
                        src={item.picture}
                        alt={item.title}
                      />
                    </div>
                    <div className={classnames(styles["news-infos"])}>
                      <div className={classnames(styles["news-title"])}>
                        {item.title}
                      </div>
                      <div className={classnames(styles["news-content"])}>
                        {item.content}
                      </div>
                      <div className={classnames(styles["news-footer"])}>
                        <div className={classnames(styles["news-source"])}>
                          {item.source}
                        </div>
                        <a
                          className={classnames(styles["news-link"])}
                          href={item.link}
                          target="_blank"
                        ></a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classnames(styles["contract-us"])}>
            <div className={classnames(styles["title-container"])}>
              <div className={classnames(styles["column-title"])}>
                {data.contractUs}
              </div>
            </div>
            <div className={classnames(styles["card-container"])}>
              <div className={classnames(styles["email-groups"])}>
                {data.contractWays.map(({ label, value }) => (
                  <div className={classnames(styles["email-item"])} key={label}>
                    <div className={classnames(styles["email-label"])}>
                      {label}
                    </div>
                    <div className={classnames(styles["email-value"])}>
                      {value}
                    </div>
                  </div>
                ))}
              </div>
              <div className={classnames(styles["qr-codes"])}>
                {data.qrCodes.map(({ title, picture }) => {
                  return (
                    <div
                      className={classnames(styles["qr-code-box"])}
                      key={title}
                    >
                      <img
                        className={classnames(styles["qr-code-picture"])}
                        src={picture}
                        alt={title}
                      />
                      <div className={classnames(styles["qr-code-title"])}>
                        {title}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classnames(styles["background-footer"])}>
        {data.footer.map((msg, index) => (
          <div key={index} className={classnames(styles["footer-span"])}>
            {msg}
          </div>
        ))}
        <div className={classnames(styles["footer-span"])}>
          备案号:<a href="https://beian.miit.gov.cn" target="_blank">京ICP备16042611号</a> 营业执照 公司邮箱: contact@hongbulin.com
          </div>
      </div>
    </div>
  );
};

export default AboutUs;
